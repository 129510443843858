import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

export default function WebDesignPage() {
    return (
        <Layout>
            <div id='webDesign_page'>
                <div id='webDesign_banner'>
                    <h1>Custom Website Design</h1>
                    <h2>Unique. Memorable. Customer-Oriented.</h2>
                    <Link to='/Pricing/'>
                        <button>Plans & Pricing</button>
                    </Link>
                </div>
                <h1>Premium Services</h1>
                <div id='premiumServices_element'>
                    <div className='premiumServices_component'>
                        <div>
                            <img alt='mobileappsimg.png' src='https://i.imgur.com/t2nQaJf.png'></img>
                            <h3>Mobile Apps</h3>
                            <p>Our websites are built with mobile responsiveness in mind, allowing for fast, interactive sites on every platform.</p>
                        </div>
                        <div>
                            <img alt='customdesignimg.png' src='https://i.imgur.com/xwFJOQd.png'></img>
                            <h3>Custom Design</h3>
                            <p>Expert designers will ensure that your website is sleek, fine-tuned, and most importantly, effective at establishing brand messaging.</p>
                        </div>
                        <div>
                            <img alt='consultingsimg.png' src='https://i.imgur.com/uJCqiiP.png'></img>
                            <h3>Account Management</h3>
                            <p>Your dedicated account manager will make sure your questions are answered as quickly as possible.</p>
                        </div>
                    </div>
                    <div className='premiumServices_component'>
                        <div>
                            <img alt='securityimg.png' src='https://i.imgur.com/uDbjylv.png'></img>
                            <h3>Security</h3>
                            <p>Our Site Security Team will constantly monitor your website to prevent foriegn intrustion.</p>
                        </div>
                        <div>
                            <img alt='brandingimg.png' src='https://i.imgur.com/CjERsm0.png'></img>
                            <h3>Branding</h3>
                            <p>We will develop custom branding guidelines to give you a unique and memorable brand identity.</p>
                        </div>
                        <div>
                            <img alt='performance.png' src='https://i.imgur.com/vFcKu7V.png'></img>
                            <h3>Performance</h3>
                            <p>Improved performance increases customer engagement and keeps your site running smoothly.</p>
                        </div>
                    </div>
                </div>
                <div id='webdesign_banner2'>
                    <div className='digitalMarketingService_container'>
                        <div>
                            <img alt='SEOlearnmore.png' src='https://i.imgur.com/bvcpmSZ.png'></img>
                            <h3>Search Engine Optimization</h3>
                            <Link to='/SEO/'>
                                <button>Learn More &#62;</button>
                            </Link>
                        </div>
                        <div>
                            <img alt='Webdesignlearnmore.png' src='https://i.imgur.com/yVdRk22.png'></img>
                            <h3>Digital<br />Marketing</h3>
                            <Link to='/DigitalMarketing/'>
                                <button>Learn More &#62;</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

